import React from "react"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import SocialReviews from "../components/SocialReviews/SocialReviews"

import { graphql } from "gatsby"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

const ContactUsPage = props => {
  const post = props.data.allUniquePagesJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout layoutClass={"contact-fab"}>
        <SEO title={post.metaTitle} description={post.metaDescription} />
        <span className="menu-spacer"></span>

        <div className="contact-top-wrapper">
          <NuvoImage
            useAR
            className="splash"
            cloudName="nuvolum"
            publicId={post.googleMapsBackground.splashPublicId}
            width="auto"
          ></NuvoImage>

          <a href={post.googleMapsBackground.pinLink} target="_blank">
            <div className="marker-heading">
              <img
                cloudName="nuvolum"
                className="marker"
                src="/location-marker.png"
                width="auto"
              />
              <h5>
                {post.googleMapsBackground.pinTextLineOne}
                <br />
                {post.googleMapsBackground.pinTextLineTwo}
              </h5>
            </div>
          </a>
          <div className="contact-top-headings">
            <h1>{post.heading}</h1>
          </div>
        </div>

        <div className="body-section">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column is-10">
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    post.contactBody.heading + post.contactBody.blurb
                  )
                )}
              ></div>
            </div>
            <div className="column is-1"></div>
            <div className="column contact-info is-hidden-mobile">
              <div className="contact-sidebar-block">
                <h7>Address</h7>
                <a href={post.googleMapsBackground.pinLink} target="_blank">
                  {post.sidebar.address.street}
                  <br />
                  {post.sidebar.address.cityStateZip}
                </a>
              </div>
              <div className="contact-sidebar-block">
                <h7>Phone</h7>
                <a href={post.sidebar.phone.phonePlusFormat}>
                  {post.sidebar.phone.phoneText}
                </a>
              </div>

              <div className="contact-sidebar-block">
                <h7>Hours</h7>
                {post.sidebar.hours.map(day => (
                  <p>{day}</p>
                ))}
              </div>
              <div className="contact-reviews">
                <SocialReviews />
              </div>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($loneUrl: String!) {
    allUniquePagesJson(filter: { title: { eq: $loneUrl } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
  }
`

export default ContactUsPage
