import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import fbRecommendations from "./fb-recommendations.svg"
import Stars from "./Stars"

import PropTypes from "prop-types"

class SocialReviews extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query googleFacebookQuery {
            googleJson {
              data {
                rating
                totalReviews
                url
              }
            }
            facebookJson {
              data {
                rating
                totalReviews
                url
              }
            }
          }
        `}
        render={data => {
          const google = data.googleJson.data
          const facebook = data.facebookJson.data

          const ratingTwoDigits = google.rating.toFixed(1);

          return (
            <div className="social-reviews">
              <div className="featured-social">
                <a
                  className="link-wrapper"
                  href={google.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>Google</h3>
                </a>
                <a
                  className="link-wrapper"
                  href={google.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="review-icons">
                    <h4 className="google-rating">{ratingTwoDigits}</h4>
                    <Stars rating={google.rating} />
                  </div>
                </a>
                <a href={google.url} target="_blank">
                  {google.totalReviews}{" "}
                  {this.props.language === "es" ? "Reseñas" : "Reviews"}
                </a>
              </div>
              <div className="featured-social">
                <a
                  className="link-wrapper"
                  href={facebook.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>Facebook</h3>
                </a>
                <a
                  className="link-wrapper"
                  href={facebook.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="review-icons">
                    <div className="svg-wrapper">
                      <img
                        src={fbRecommendations}
                        alt="Facebook Reviews"
                      />
                    </div>
                  </div>
                </a>
                <a href={facebook.url} target="_blank">
                  {facebook.totalReviews}{" "}
                  {this.props.language === "es"
                    ? "Reseñas"
                    : "Reviews"}
                </a>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

SocialReviews.propTypes = {
  language: PropTypes.string,
}

export default SocialReviews
